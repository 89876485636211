<nz-layout *ngIf="isAuthenticated" dir="rtl" class="app-layout">
  <nz-layout>
    <header class="header">
      <img (click)="returnToHomePage()" src="assets/images/home-web-icon.svg" alt="home-web-icon"
        class="right-header" />
      <a (click)="returnToHomePage()">
        <img src="assets/images/logo.svg" alt="logo" class="tktah-logo" />
      </a>
      <a href="tel:+569996970" (click)="makePhoneCall()">
        <img src="assets/images/support-web-icon.png" alt="support-web-icon" class="left-header" />
      </a>
    </header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout *ngIf="!isAuthenticated">
      <header class="header">
        <img src="assets/images/logo.svg" alt="logo" class="tktah-logo" />
    </header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>