import { environment } from "../../environments/environment";
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface IOtp {
  mobile: string;
  otp?: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private httpClient: HttpClient,
  ) { }

  requestOtp(otp: IOtp) {
    return this.httpClient.post(`${environment.apiURL}api/gate-keeper/user/request-otp/`, otp)
  }

  verfiyOtp(otp: IOtp) {
    return this.httpClient.post(`${environment.apiURL}api/gate-keeper/user/verify-otp`, otp)
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }
    const { roles } = this.jwtHelper.decodeToken(token);
    if (!roles || (roles.length && !roles.includes('gate-keeper'))) {
      return false;
    }
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }
  viewUser() {
    return this.httpClient.get(`${environment.apiURL}api/gate-keeper/user/view`)
  }
  public getToken(): string {
    const token = localStorage.getItem('token') || '';

    // return token ? JSON.parse(token) : null;
    return token
  }
  getUserInfo() {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }
    return this.jwtHelper.decodeToken(token);
  }
  getGateKeeperId() {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }
    return this.jwtHelper.decodeToken(token)._id;
  }
}
